import { HeaderComponent } from 'components/header/component'
import { preventDefaultThen } from 'components/link'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import * as GenericRedux from 'generic/genericRedux'
import React, { Component } from 'react'
import { Button } from 'reactstrap'
import * as Redux from 'redux'
import { injectReducer } from 'store/index'

import './_styles.scss'
import * as Constants from './constants'
import * as Types from './types'

class SourceCheckComponent extends Component<Types.Props, Types.State> {
  public static contextType = RasaContext

  constructor(props: Types.Props) {
    super(props)
    this.state = Constants.InitialState
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.setState({
        isLoading: false,
        selectedCommunity: activeCommunity.communityId,
      })
    })
  }

  public render() {
    return <div className="source-check-wrapper">
      <HeaderComponent
        icon={'contentHistory'}
        title={'CHECK SOURCE'}
        subTitle={'SOURCE CHECK'}
        description={[
          'See why we are not able to find a feed.',
        ]}/>
      {this.state.isLoading ?
        <Loading size="64"/>
      :
        <div>
          <div className="input-area">
            <form onSubmit={preventDefaultThen(() => this.getSourceCheckResults())}>
              <input autoFocus disabled={this.state.isLoading} className="field-textbox"
                placeholder="Enter the URL to review"
                value={this.state.url}
                onChange={(e: any) => this.setState({ url: e.target.value })}
              />
              {this.state.stillNoLuck ? 
              <div className='still-no-luck-box'><input autoFocus disabled={this.state.isLoading} className="field-textbox"
                placeholder="Enter the HTTP headers to try"
                value={this.state.headers}
                onChange={(e: any) => this.setState({ headers: e.target.value })}
              /></div>: null}
              <span className="buttons">
                {this.state.stillNoLuck ? <Button onClick={() => {
                  this.setState({
                    ...Constants.InitialState,
                    selectedCommunity: this.state.selectedCommunity,
                    isLoading: false,
                  })
                }}>Reset</Button>: null}
                <Button disabled={this.state.url.length === 0} >Fetch</Button>
              </span>
            </form>
          </div>
          <div className='info-msg'>
            {this.state.data.success
            ? this.state.data.article_fetch_error ?
              <span className="warning">{this.state.data.article_fetch_error}</span>
            : <span className="info">We are able to locate feed for this URL. You can add this feed without any issues.</span>
            : this.state.data.node_check_results.length > 0 ? <div className="warning">We are not able to locate feed for this URL. Here are possible option we tried.</div>: null} 
            <div className='warning'>{this.state.error}</div>
          </div>
          {this.state.data.node_check_results.length > 0 ?
          <div>
            {this.renderSourceCheckResults()}
          </div>: null}
          
        </div>
      }
    </div>
  }

  private renderSourceCheckResultItem = (result: Types.SourceFetchResult) => {
    return <div className='check-result-item'>
      <div className="item-row">
        <label>Headers:</label><span>{result.http_headers.replace('\"', '"')}</span>
      </div>
      <div className="item-row">
        <label>Success:</label><span>{String(result.success)}</span>
      </div>
      <div className="item-row">
        <label>Message:</label><span>{result.message}</span>
      </div>
    </div>
  }

  private renderSourceCheckResults = () => {
    return <div className='feed-fetch-results'>
      {this.state.data.node_check_results.length > 0 ? 
      <div className="node-check">
        <div className="heading">Node Check</div>
        {this.state.data.node_check_results.map((result) => (
          this.renderSourceCheckResultItem(result)
        ))}
      </div> : null}
      {this.state.data.python_check_results.length > 0 ? 
      <div className="python-check">
        <div className="heading">Python Check</div>
        {this.state.data.python_check_results.map((result) => (
          this.renderSourceCheckResultItem(result)
        ))}
      </div> : null}
    </div>
  }

  private getSourceCheckResults = () => {
    this.setState({isLoading: true, data: Constants.InitialState.data, error: ''}, () =>
    AjaxWrapper
      .ajax(`${AjaxWrapper.getServerUrl()}/cs/${this.state.selectedCommunity}/source-check`, HttpMethod.POST, {
        url: this.state.url,
        headers: this.state.headers,
      })
      .then((response: Types.SourceCheckResult) => {
        this.setState({
          data: response,
          stillNoLuck: !response.success && response.node_check_results.filter((x) => x.success).length === 0 && response.python_check_results.filter((x) => x.success).length === 0,
          isLoading: false,
        })
      })
      .catch((err) => {
        this.setState({
          error: 'An error occurred while fetching the results.',
          isLoading: false,
        })
      }))
  }
}


export const SourceCheck = GenericRedux.createConnect(SourceCheckComponent, Constants.REDUX_STORE_HOME)
injectReducer(
  Constants.REDUX_STORE_HOME,
  Redux.combineReducers({
    data: GenericRedux.createGenericReducer(Constants.REDUX_STORE_HOME, {}),
  }),
)
