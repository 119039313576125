import { State } from "./types"

export const REDUX_STORE_HOME = 'article_activity'

export const InitialState: State = {
  data: [],
  error: '',
  isLoading: true,
  isSaving: false,
  selectedCommunity: '',
  success: false,
  url: [],
  headers: '',
}
