import { HeaderComponent } from 'components/header/component'
import { preventDefaultThen } from 'components/link'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import * as GenericRedux from 'generic/genericRedux'
import capitalize from 'lodash/capitalize'
import React, { Component } from 'react'
import { Button, Col, Row } from 'reactstrap'
import * as Redux from 'redux'
import { injectReducer } from 'store/index'

import './_styles.scss'
import * as Constants from './constants'
import * as Types from './types'

class ArticleActivityComponent extends Component<Types.Props, Types.State> {
  public static contextType = RasaContext

  constructor(props: Types.Props) {
    super(props)
    this.state = {
      data: {
        articles: [],
        last_issue: {},
        message: '',
        schedule: {},
        schedule_history: [],
        success: true,
        upcoming_issue: {},
      },
      error: '',
      isLoading: true,
      selectedCommunity: '',
      url: '',
    }
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.setState({
        isLoading: false,
        selectedCommunity: activeCommunity.communityId,
      })
    })
  }

  public render() {
    return <div className="article-activity-wrapper">
      <HeaderComponent
        icon={'contentHistory'}
        title={'ACTIVITY'}
        subTitle={'Article Activity'}
        description={[
          'Review activity for a article in the newsletter.',
        ]}/>
      {this.state.isLoading ?
        <Loading size="64"/>
      :
        <div>
          <div className="input-area">
            <form onSubmit={preventDefaultThen(() => this.getArticleActivity())}>
              <input autoFocus disabled={this.state.isLoading} className="field-textbox"
                placeholder="Enter the URL to review"
                value={this.state.url}
                onChange={(e: any) => this.setState({ url: e.target.value })}
              />
              <Button disabled={this.state.url.length === 0} >Get Activity</Button>
            </form>
          </div>
          {this.state.error.length
          ? <span className="warning" dangerouslySetInnerHTML={{__html: this.state.error || 'We could not locate a feed for this URL'}} />
          : this.renderActivity()
          }
        </div>
      }
    </div>
  }

  private renderActivity = () => {
    if (this.state.isLoading || !this.state.data.success || !this.state.data.articles.length) {
      return null
    }
    return <div>
      <div><Row className='header-row'><Col><b>Activity for : {this.state.url}</b></Col></Row></div>
      {this.state.data.articles.map((articleActivity: Types.ArticleActivity) => {
        return <div className="article-activity">{this.renderArticleInfo(articleActivity)}</div>
      })}
      {this.renderHistory(this.state.data.schedule_history)}
      {this.renderSchedule()}
      {this.renderLastIssue()}
      {this.renderUpcomingIssue()}
    </div>
  }

  private renderArticleInfo = (articleActivity: Types.ArticleActivity) => {
    return <div className="article-info">
      <Row className='header-row'>
        <Col><b>Article: </b>{articleActivity.article.id}</Col>
      </Row>
      <Row>
        <Col><b>Created: </b> {articleActivity.article.created}</Col>
        <Col><b>Original Created: </b> {articleActivity.article.original_created}</Col>
        <Col><b>Updated: </b> {articleActivity.article.updated}</Col>
      </Row>
      <Row>
        <Col><b>Is Active: </b> {articleActivity.article.is_active}</Col>
        <Col><b>Boost Level: </b> {articleActivity.article.boost_level}</Col>
        <Col><b>Reserved: </b> {articleActivity.article.is_reserved || 0}</Col>
      </Row>
      <Row>
        <Col><b>Source Type: </b> {articleActivity.article.source_type}</Col>
        <Col><b>Subscription Type: </b> {articleActivity.article.subscription_type}</Col>
        <Col><b>Section Name: </b> {articleActivity.article.section_name || ''}</Col>
      </Row>
      <Row>
        <Col><b>Source Active: </b> {articleActivity.article.subscription_is_active}</Col>
        <Col><b>Source Updated: </b> {articleActivity.article.subscription_updated}</Col>
        <Col></Col>
      </Row>
      {this.renderHistory(articleActivity.history)}
      {this.renderHistory(articleActivity.api_history)}
      {this.renderHistory(articleActivity.updated_history)}
      {this.renderHistory(articleActivity.scheduled_article_history)}
    </div>
  }

  private renderSchedule = () => {
    const schedule = this.state.data.schedule
    if (!schedule.id) {
      return null
    }
    return <div className="schedule">
      <Row className='header-row'><Col><b>Schedule: </b></Col></Row>
      <Row>
        <Col><b>Time: </b> {schedule.time}</Col>
        <Col><b>Cutoff Offset: </b> {(schedule.cutoff_offset || 0) / 60} Hrs.</Col>
        <Col><b>Is Active: </b> {schedule.is_active}</Col>
      </Row>
      <Row>
        <Col><b>Frequency: </b> {schedule.frequency}</Col>
        <Col><b>Days: </b> {schedule.days}</Col>
        <Col><b>Weeks: </b> {schedule.weeks}</Col>
      </Row>
      <Row>
        <Col><b>Timezone: </b> {schedule.timezone}</Col>
        <Col><b>Updated: </b> {schedule.updated}</Col>
        <Col><b>Start Date: </b> {schedule.start_date}</Col>
      </Row>
    </div>
  }

  private renderLastIssue = () => {
    const lastIssue = this.state.data.last_issue
    return <div className="issues">
      <Row className='header-row'><Col><b>Last Issue: </b></Col></Row>
      <Row>
        <Col><b>Date: </b> {lastIssue.send_at}</Col>
        <Col><b>Cutoff: </b> {lastIssue.cutoff}</Col>
        <Col><b>Last Cutoff: </b> {lastIssue.last_cutoff}</Col>
      </Row>
    </div>
  }

  private renderUpcomingIssue = () => {
    const nextIssue = this.state.data.upcoming_issue
    if (!nextIssue.date) {
      return null
    }
    return <div className="issues">
      <Row className='header-row'><Col><b>Next Issue: </b></Col></Row>
      <Row>
        <Col><b>Date: </b> {nextIssue.date}</Col>
        <Col><b>Cutoff: </b> {nextIssue.cutoff}</Col>
        <Col><b>Is Active: </b> {nextIssue.is_active ? '1' : '0'}</Col>
      </Row>
    </div>
  }

  private renderHistory = (history: Types.AuditLog[]) => {
    if (!history.length) {
      return null
    }
    return <div className="history">
      <Row className='header-row'><Col><b>{capitalize(history[0].entity_name)} History: </b></Col></Row>
      {(history || []).map((auditLog: Types.AuditLog) => this.renderAuditLog(auditLog))}
    </div>
  }

  private renderAuditLog = (auditLog: Types.AuditLog) => {
    return <ul className="audit-log">
      <li>
        <Row><Col><b>Created: </b> {auditLog.created}</Col></Row>
        <Row><Col><b>Change: </b> {auditLog.change}</Col></Row>
        <Row><Col><b>User: </b> {auditLog.user_email}</Col></Row>
      </li>
    </ul>
  }

  private getArticleActivity = () => {
    this.setState({isLoading: true, data: null, error: ''}, () =>
    AjaxWrapper
      .ajax(`${AjaxWrapper.getServerUrl()}/cs/${this.state.selectedCommunity}/article-activity`, HttpMethod.POST, {
        url: this.state.url,
      })
      .then((response: Types.ArticleActivityResult) => {
        if (response.success) {
          this.setState({
            data: response,
          })
        } else {
          this.setState({
            error: response.message || 'Unknown Error',
          })
        }
        this.setState({
          isLoading: false,
        })
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('err', err)
        this.setState({
          error: (err.response || {message: 'Unknown Error'}).message,
          isLoading: false,
        })
      }))
  }
}

export const ArticleActivity = GenericRedux.createConnect(ArticleActivityComponent, Constants.REDUX_STORE_HOME)
injectReducer(
  Constants.REDUX_STORE_HOME,
  Redux.combineReducers({
    data: GenericRedux.createGenericReducer(Constants.REDUX_STORE_HOME, {}),
  }),
)
