import { HeaderComponent } from 'components/header/component'
import { preventDefaultThen } from 'components/link'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import * as GenericRedux from 'generic/genericRedux'
import React, { Component } from 'react'
import { Button, Input } from 'reactstrap'
import * as Redux from 'redux'
import { injectReducer } from 'store/index'

import './_styles.scss'
import * as Constants from './constants'
import * as Types from './types'

class BulkSourceComponent extends Component<Types.Props, Types.State> {
  public static contextType = RasaContext

  constructor(props: Types.Props) {
    super(props)
    this.state = Constants.InitialState
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.setState({
        isLoading: false,
        isSaving: false,
        selectedCommunity: activeCommunity.communityId,
      })
    })
  }

  public componentDidUpdate(prevProps, prevState) {
    if (this.state.success && !prevState.success) {
      setTimeout(() => {
        this.setState({ success: false })
      }, 5000)
    }
  }


  public render() {
    return <div className="source-check-wrapper">
      <HeaderComponent
        icon={'contentHistory'}
        title={'Bulk Source Import'}
        subTitle={'Bulk Source Import'}
        description={[
          'Import bulk source history.',
        ]}/>
      {this.state.isLoading ?
        <Loading size="64"/>
        :
        <div>
          <div className="input-area">
            <form onSubmit={preventDefaultThen(() => this.getSourceCheckResults())}>
              <Input autoFocus disabled={this.state.isLoading} type="textarea" value={this.state.url.join('\n')}
                     placeholder="Enter the URL to review"
                     onChange={(e: any) => this.handleTextAreaChange(e)} />
              <div className="form-button-wrap">
                <Button disabled={this.state.url.length === 0} >Fetch</Button>
                {this.state.url.length > 0  && <Button
                    onClick={() => this.setState({data: Constants.InitialState.data, error: '', url: []})} >
                    Reset</Button> }
              </div>
            </form>
          </div>
          <div className='info-msg'>
            {this.state.data.length > 0 &&
                <div className="table-responsive table-wrapper">
                    <table className="table result-table">
                        <thead>
                        <tr>
                            <th>Url</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.data.map((result) => (
                          <tr>
                            <td>{result.url}</td>
                            <td>{result.status}</td>
                          </tr>))}
                        </tbody>
                    </table>
                  {this.state.isSaving ? <Loading size="32"/> :
                    <Button onClick={this.addSources}>Save Source</Button>}
                </div>
            }
            <div className='warning'>{this.state.error}</div>
            <div className='success'>{this.state.success ? 'Source successfully add': ''}</div>
          </div>
        </div>
      }
    </div>
  }

  private handleTextAreaChange = (e) => {
    const text = e.target.value
    const urlsArray = text.split(/[\n,]+/)
    this.setState({url: urlsArray})
  }

  private addSources = () => {
    const result = this.state.data.map((e) => {
      if (e.status !== 'Invalid feed' && e.url && e.name) {
        return {
          url: e.url,
          name: e.name
        }
      }
      return null
    }).filter(url => url !== null)
    this.setState({isSaving: true, data: Constants.InitialState.data, error: ''}, () =>
      AjaxWrapper
        .ajax(`${AjaxWrapper.getServerUrl()}/cs/${this.state.selectedCommunity}/bulk-source-add`, HttpMethod.POST, {
          payload: result,
        })
        .then((response: any) => {
          this.setState({
            success: true,
            isSaving: false,
          })
        })
        .catch((err) => {
          this.setState({
            error: 'An error occurred while saving the results.',
            isSaving: false,
          })
        }))
  }


  private getSourceCheckResults = () => {
    this.setState({isLoading: true, data: Constants.InitialState.data, error: ''}, () =>
      AjaxWrapper
        .ajax(`${AjaxWrapper.getServerUrl()}/cs/${this.state.selectedCommunity}/bulk-source`, HttpMethod.POST, {
          url: this.state.url,
          headers: this.state.headers,
        })
        .then((response: Types.SourceCheckResult[]) => {
          this.setState({
            data: response,
            isLoading: false,
          })
        })
        .catch((err) => {
          this.setState({
            error: (err.response.error || {message: 'An error occurred while fetching the results.'}).message,
            isLoading: false,
          })
        }))
  }
}


export const BulkSourceImport = GenericRedux.createConnect(BulkSourceComponent, Constants.REDUX_STORE_HOME)
injectReducer(
  Constants.REDUX_STORE_HOME,
  Redux.combineReducers({
    data: GenericRedux.createGenericReducer(Constants.REDUX_STORE_HOME, {}),
  }),
)
